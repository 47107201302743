import React, { useEffect, useRef, useState } from 'react'
import { MainEventsContainer } from '../../../MainEvents/styles'
import { BuddyTab, BuddyTabContainer } from '../../../PeopleHomeV2/styles'
import HoverButton from '../../../common/HoverButton'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { OverlayDashboard, HeaderWrapper } from '../../../HealthTabV2/styles'
import { BackIcon, DownloadIconNew, 
  PaginationIcon, 
  starIcon, userIcon } from '../../../../utils/icons'
import { toast } from 'react-toastify'
import { FetchFeedBackQuestions, FetchSurveyFeedbackInsights, ToggleStarFeedback } from '../../../../redux/constants/apiConstants'
import DropDown from '../../../common/CommonDropDown/DropDown'
import { FeedbackCard, QuestionDetails } from './style'
import LazyImage from '../../../common/LazyImage/LazyImage'
import { ImageUrl } from '../../../../utils/constants'
// import { PaginationCard } from '../../../DailyTipAdmin/styles'
import { UserButton } from '../../../EngagementSection/styles'
import { NoData } from '../../../ReportsV2/styles'
import { getSurveyReportData } from '../QuestionInsight'
import { fetchApi } from '../../../../utils/methods'
import SkeletonLoder from '../../../common/skeletonLoder'
import { PaginationCircleNew } from '../../../Journeys/styles'

const FeedBack = (props) => {

  const{history,
    // role
  } = props;
  const[isLoading, setIsLoading] = useState(false);
  const[feedbackInsights, setFeedbackInsights] = useState({});
  const[sortValue, setSortValue] = useState({ value: 0, text: "All",key:'normal' });
  const [filetrOptions, setFilterOptions]= useState([
    { value: 0, text: "All",key:'normal' }
  ]);
  const[starred, setStarred]=useState(0);
  const[pageNo, setPageNo,] = useState(1);
  const[paginationCount, setPaginationCount] = useState(100);
  const [minLimit, setMinLimit] = useState(1);
  const [maxLimit, setMaxLimit] = useState(6);
  const [total, setTotal] = useState(100); // Assuming a default total value
  // const [redirectToTop, setRedirectToTop] = useState(false);
  const [countingValues, setCountingValues] = useState(10);
  const [surveyDetails, setSurveyDetails] = useState({});

  const componentMounted = useRef(true); // (3) compo

  useEffect(()=>{
    // getFeedbackInsights(props.match.params.id, history?.location?.state?.questionId ? history?.location?.state?.questionId : "","");
    getShortQuestionsList(props.match.params.id);
    let companyId = props.companyInfo.id;
    let surveyId = props.match.params.id;
    setSurveyDetails({companyId,surveyId});
    getSurveyFeedback(companyId,surveyId,starred,history?.location?.state?.questionId,pageNo, false);
    // fetchExcelData();
    return () => { // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    }
  },[]);

  useEffect(() => {
    if (paginationCount && countingValues !== paginationCount) {
      const newTotal = Math.ceil(paginationCount / 50);
      setTotal(newTotal);
      setCountingValues(paginationCount);

      if (newTotal < 6) {
        setMaxLimit(newTotal);
      } else {
        setMaxLimit(6);
      }
    }
  }, [paginationCount, countingValues]); // Trigger effect when paginationCount or countingValues changes


  const getSurveyFeedback = async(companyId,suvreyId,starred=0,questionId=0, page=1,isDropdown )=>{
    setIsLoading(true);
    let apiUrl = FetchSurveyFeedbackInsights +suvreyId + "/" + companyId + "/" + (starred ? 1 : 0 ) + "/" + questionId + "/" + (isDropdown ? 1 : page) + "/" + 50;

    try{
      const res = await fetchApi(apiUrl,"GET");
      window.console.log("res",res);
      if(res.status === "failed"){
        toast.error(res?.data?.message)
      }else if(res.status === "success"){
        setFeedbackInsights(res?.data?.question_answers);
        setPaginationCount(res?.data?.count || 100);
        window.console.log("questionId",questionId);
        // window.console.log("questionsArr[0]",questionsArr[0]);
            
        setIsLoading(false);
        let questionsArr=[];

        {res?.data?.question_answers && Object.keys(res?.data?.question_answers).map((feedback)=>{
          if (res?.data?.question_answers?.[feedback].question_id
          ) {
            const newQuestion = {
              value: Number(res?.data?.question_answers[feedback].question_id),
              text: "Question" + " " + res?.data?.question_answers[feedback].question_number,
              que: res?.data?.question_answers[feedback].question
            };
          
            // Check if the new question is not already in questionsArr before pushing
            if (!questionsArr.some((question) => question.value === newQuestion.value)) {
              questionsArr.push(newQuestion);
            }
          }
        
        })}
        if(res?.data?.question_answers && Object.keys(res?.data?.question_answers).length >= 1){
          window.console.log("hii");
          !questionId && res?.data?.question_answers && Object.keys(res?.data?.question_answers).length > 1 && questionsArr.unshift({ value: 0, text: "All",key:'normal' });
          if(isDropdown){
            window.console.log("questionId if",questionId);
            window.console.log("questionsArr[0]",questionsArr[0]);
            questionId !== 0 && questionsArr.unshift({ value: 0, text: "All",key:'normal' });
            const selectedQuestion = questionsArr.filter((item)=>item.value == questionId);
            setSortValue(selectedQuestion[0])
          }else{
            window.console.log("questionId else",questionId);
            window.console.log("questionsArr[0]",questionsArr[0]);
            questionId && res?.data?.question_answers && Object.keys(res?.data?.question_answers).length >= 1 && setSortValue(questionsArr[0])

          }


        }
        setIsLoading(false);

      }
    }catch (error) {
      setIsLoading(false);
      window.console.log(error);
      // setIsOverviewLoading(false);
    }

  }

  const getShortQuestionsList = async(surveyId) => {

    // setIsLoading(true);
    try {
      const res = await fetchApi(FetchFeedBackQuestions + surveyId,"GET");

      if(res?.data?.message){
        toast.error(res.data.message)
      }else{

        const questionList = [];
        for (let i = 0; i < res?.data.length; i++) {
          let obj = {
            value: Number(res?.data?.[i]?.id),
            text: "Question" + " " + res?.data?.[i]?.weight,
            que: res?.data?.[i]?.question
          }
          questionList.push(obj)
          
        }
        questionList.unshift({ value: 0, text: "All",que:'' });
        setFilterOptions(questionList)
      }
    } catch (error) {
      toast.error(error);
      // setIsLoading(false);
    }
  };



  // const getShortQuestionsList = async(surveyId)=>{
  
  //   const AuthToken = localStorage.getItem("AUTH_TOKEN");
  //   let url = BASE_URL + ShortQuestionsList + `/${surveyId}`;
    
  //   await fetch(url, {
  //     method:"GET",
  //     headers: {
  //       authtoken: AuthToken,
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     // body: JSON.stringify(surveyDetails),
  //   })
  //     .then((res) => {
  //       res
  //         .json()
  //         .then((data) => {
  //           if (res.status === 200 || res.status === 201) {
  //             const questionList = [];
  //             for (let i = 0; i < data.feedback_questions.length; i++) {
  //               let obj = {
  //                 value: Number(data.feedback_questions[i].id),
  //                 text: "Question" + " " + data.feedback_questions[i].weight,
  //                 que: data.feedback_questions[i].question
  //               }
  //               questionList.push(obj)
                
  //             }
  //             questionList.unshift({ value: 0, text: "All",que:'' });
  //             setFilterOptions(questionList)
              
  //           } else {
  //             toast.error(data.data.message);
  //           }
  //         })
  //         .catch((error) => {
  //           toast.error(error.message);
  //         });
  //     })
  //     .catch((error) => {
  //       toast.error(error.message);
  //     });
  // }


  // const getFeedbackInsights = async(surveyId,questionId,starred, nexpageUrl,isDropdown) => {
  //   setIsLoading(true);
  //   const AuthToken = localStorage.getItem('AUTH_TOKEN');

  //   const match = feedbackInsights && feedbackInsights.next_page_url && feedbackInsights.next_page_url.match(/[?&]page=([^&]+)/);

  //   if (match) {
  //     const pageValue = match[1];
  //     setPageNo(pageValue && pageValue)
  //   } 
    

  //   let url = nexpageUrl ? nexpageUrl + (questionId &&  ("&questionId=" + questionId)) + (questionId ? "&" : "&")+ "starred="+(starred ? starred : "") + "&perPage="+10 : BASE_URL + GetSurveyFeedbackInsights + "/" + surveyId + (questionId &&  ("?questionId=" + questionId)) + (questionId ? "&" : "?")+ "starred="+(starred ? starred : "") + "&perPage="+10 + "&page="+ (isDropdown ? 1 : pageNo);
  //   await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } }).then(
  //     (res) => {
  //       res.json().then((data) => {
  //         if(res.status === 200){
  //           setFeedbackInsights(data.feedback_insights)
            
  //           setIsLoading(false);
  //           let questionsArr=[];

  //           {Object.keys(data.feedback_insights).map((feedback)=>{
  //             if (
  //               data.feedback_insights &&
  //               data.feedback_insights[feedback] &&
  //               data.feedback_insights[feedback].question_number && data.feedback_insights[feedback].question_id
  //             ) {
  //               const newQuestion = {
  //                 value: Number(data.feedback_insights[feedback].question_id),
  //                 text: "Question" + " " + data.feedback_insights[feedback].question_number,
  //                 que: data.feedback_insights[feedback].question
  //               };
              
  //               // Check if the new question is not already in questionsArr before pushing
  //               if (!questionsArr.some((question) => question.value === newQuestion.value)) {
  //                 questionsArr.push(newQuestion);
  //               }
  //             }
            
  //           })}
  //           if(Object.keys(data.feedback_insights).length > 1){
  //             !questionId && data && data.feedback_insights && Object.keys(data.feedback_insights).length > 1 && questionsArr.unshift({ value: 0, text: "All",key:'normal' });
  //             if(isDropdown){
  //               questionId !== 0 && questionsArr.unshift({ value: 0, text: "All",key:'normal' });
  //               const selectedQuestion = questionsArr.filter((item)=>item.value == questionId);
  //               setSortValue(selectedQuestion[0])
  //             }else{
  //               questionId && data && data.feedback_insights && Object.keys(data.feedback_insights).length > 1 && setSortValue(questionsArr[0])

  //             }
  //             // setFilterOptions(questionsArr);

  //           }else{
  //             // setFilterOptions([{ value: 0, text: "All",key:'normal' }]);

  //           }

  //         }else{
  //           toast.error(data.data.message);
  //         }

  //       }).catch((error)=>{
  //         toast.error(error.message);
  //       });
  //     }
  //   ).catch((error)=>{
  //     toast.error(error.message);
  //   });
  // };

  // const ToggleStar = async(responseId )=>{

  //   const AuthToken = localStorage.getItem("AUTH_TOKEN");
  //   let url = BASE_URL + FeedbackToggleStar + `/${responseId}`;

  //   await fetch(url, {
  //     method:"POST",
  //     headers: {
  //       authtoken: AuthToken,
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     // body: JSON.stringify(surveyDetails),
  //   })
  //     .then((res) => {
  //       res
  //         .json()
  //         .then((data) => {
  //           if (res.status === 200 || res.status === 201) {
  //             getFeedbackInsights(props.match.params.id,sortValue?.value ? sortValue?.value : "",starred,"");
  //           } else {
  //             toast.error(data.data.message);
  //           }
  //         })
  //         .catch((error) => {
  //           toast.error(error.message);
  //         });
  //     })
  //     .catch((error) => {
  //       toast.error(error.message);
  //     });

  // }

  const ToggleStar = async(responseId) => {

    // setIsLoading(true);
    try {
      const res = await fetchApi(ToggleStarFeedback + responseId,"GET");

      if(res?.data?.message){
        toast.error(res.data.message)
      }else{
        // getFeedbackInsights(props.match.params.id,sortValue?.value ? sortValue?.value : "",starred,"");
        getSurveyFeedback(surveyDetails.companyId,surveyDetails.surveyId,starred,history?.location?.state?.questionId,pageNo, false);

      }
    } catch (error) {
      toast.error(error);
      // setIsLoading(false);
    }
  };

  const getPaginationCircle = () => {
    let circleButton = [];
    for (let i = minLimit; i <= maxLimit; i++) {
      circleButton.push(
        <PaginationCircleNew
          key={i}
          active={i == pageNo}
          onClick={() => setPagination(i)}
        >
          <div>{i}</div>
        </PaginationCircleNew>
      );
    }
    return circleButton;
  };

  const setPagination = (activeIndex) => {
    // setRedirectToTop(true);

    if (activeIndex !== pageNo) {
      if (total <= 6) {
        setMinLimit(1);
        setMaxLimit(total);
        setPageNo(activeIndex);
        // fetchEmployee();
      } else {
        if (activeIndex === 1) {
          setPageNo(activeIndex);
          setMaxLimit(6);
          setMinLimit(1);
          // fetchEmployee();
        } else {
          if (activeIndex + 3 > total) {
            setPageNo(activeIndex);
            setMaxLimit(total);
            setMinLimit(total - 4);
            // fetchEmployee();
          } else {
            setPageNo(activeIndex);
            setMaxLimit(activeIndex + 3);
            setMinLimit(activeIndex - 1);
            // fetchEmployee();
          }
        }
      }
    }
  };

  

  return (
    <React.Fragment >
      <OverlayDashboard >
        <MainEventsContainer marginTop="1" >
          <HeaderWrapper padding={1} style={{padding:'0px'}}>
            <BuddyTabContainer style={{alignItems:'center', display:"flex",flexDirection:"row", justifyContent:"space-between",}}>
              <div style={{marginRight:"15px",display:"flex", alignItems:"center"}}>
                <div style={{marginRight:"15px", display:"flex", alignItems:"center"}}>
                  <HoverButton
                    title="Back"
                    width="24px"
                    height="24px"
                    svgPath={BackIcon()} 
                    onClick={()=>{history?.location?.state?.page === "completed" ? history.push("/company/surveys/completed") :  history.push("/company/surveys");}}
                  />
                </div>
                <BuddyTab
                  width
                  padding
                  style={{margin:"0px 10px 0px 0px"}}
                  // active={activeTab===1 ? true : false}
                  // onClick={() => setActiveTab(1)}
                  onClick={()=>{history?.location?.state?.page === "completed" ? history.push({pathname:`/company/survey-analytics/${props.match.params.id}`,state:{page:"completed"}}) :  history.push(`/company/survey-analytics/${props.match.params.id}`)}}
                >
                  {"Analytics"}
                </BuddyTab>
                <BuddyTab
                  width
                  padding
                  style={{margin:"0px 10px 0px 0px"}}
                  active={false}
                  // onClick={() => setActiveTab(2)}
                  onClick={()=>{history?.location?.state?.page === "completed" ? history.push({pathname:`/company/survey-insight/${props.match.params.id}`,state:{page:"completed"}}) :  history.push(`/company/survey-insight/${props.match.params.id}`)}}
                >
                  {"Question Insight"}
                </BuddyTab>
                <BuddyTab
                  width
                  padding
                  style={{margin:"0px"}}
                  active={true}
                  // onClick={() => setActiveTab(3)}
                >
                  {"Feedback"}
                </BuddyTab>
              </div>

              {/* <div style={{display:"flex",flexDirection:"row",alignItems:"center", justifyContent:"end", width:"53%", marginRight:"10px"}}>
                {role === "ADMIN" &&  <div style={{marginRight:"10px"}}> <HoverButton title={"Download"} tooltipStyle={{marginLeft:"0px"}}
                  onClick={()=> getSurveyReportData(props.match.params.id)} 
                  svgPath={<PrintIcon />} style={{}} /></div>}
              </div> */}

              <div style={{display:"flex",flexDirection:"row",alignItems:"center", justifyContent:"end", width:"53%", marginRight:"10px"}}>
                {<div style={{marginRight:"10px"}}>               <HoverButton style={{cursor: "pointer"}} onClick={()=> getSurveyReportData(surveyDetails.surveyId, surveyDetails.companyId)}  width={"40px"} height={"40px"} title={"Download"} fillOpacity={1} disabled={false} backgroundColor={"#f0f6f8"} backgroundColorActive={"#005c87"} activeColor={"#619ab5"} inActiveColor={"white"} svgPath={<DownloadIconNew/>} /></div>}
              </div>


              
            </BuddyTabContainer>

            {isLoading ? <SkeletonLoder width={"1246px"} height={"100vh"} style={{marginTop:"20px"}}/>  : (<>

              <div style={{display:"flex",justifyContent:"space-between", alignItems:"center", marginTop:"20px"}}>
                <div>
                  {filetrOptions.length > 0 ? <DropDown
                    data={filetrOptions}
                    placeholder="Select Option"
                    valueIndex={0}
                    dropdownStyle={{ top: "30px",height:"40px"}}
                    onSelectParameter={["text", "value"]}
                    itemValue={true}
                    activeValue={true}
                    title={sortValue?.text}
                    displayValue={true}
                    valueString={""}
                    tileContainerStyle={{
                      width: "auto",
                      background: "rgba(247, 245, 235, 0.50)",
                      position: "absolute",
                      height:"40px"
                    }}
                    active={sortValue?.value}
                    onSelect={(value) => {
                      // getFeedbackInsights(props.match.params.id,value?.value === 0 ? "" : value?.value,starred,"", true);
                      getSurveyFeedback(surveyDetails.companyId,surveyDetails.surveyId,starred,value?.value === 0 ? 0 : value?.value,1, true);
                      setPagination(1);
                      setSortValue(value)
                      // sortBy(value)
                    }}
                    activityDropdownStyle={{
                      background: "rgba(247, 245, 235, 0.50)",
                      height:"40px"
                    }}
                    height={"40px"}
                  />:null}
                </div>
                <div onClick={()=>{setStarred(!starred);        setPagination(1);/*getFeedbackInsights(props.match.params.id,sortValue?.value ? sortValue?.value : "",!starred)*/
                  getSurveyFeedback(surveyDetails.companyId,surveyDetails.surveyId,!starred,sortValue?.value ? sortValue?.value : 0,1, false);
                }} style={{border:"1px solid rgba(0, 92, 135, 1)", borderRadius:"3px", height:"50px", display:'flex', alignItems:"center",justifyContent:"center", width:"120px", background:starred && "rgba(0, 92, 135, 1)", color:starred && "white", cursor:"pointer"}}>
                  <div>{starred ? starIcon("white") : starIcon()}</div>
                  <div  style={{marginLeft:"10px",fontFamily:"Rubik-Medium",fontSize:"16px",lineHeight:"20px", color:starred? "white" : "rgba(0, 92, 135, 1)"}}><span>Starred</span></div>
                </div>
              </div>
   
              {(sortValue &&  sortValue?.value && sortValue?.value !== 0) ? (<QuestionDetails>
                <div><span className='title'>Short Answers {sortValue?.text}</span></div>
                <div><span className='que'>{sortValue?.que}</span></div>
              </QuestionDetails>): ""}
   
              {feedbackInsights && Object.keys(feedbackInsights).length > 0 ? (<React.Fragment><div style={{marginTop:"20px"}}>
                {Object.keys(feedbackInsights).map((feedback)=>
                  (  feedbackInsights?.[feedback]?.answers?.map((item, ind)=>(
                    <FeedbackCard key={ind}>
                      <div className='row-one'>
                        <div className='sub-row'>
                          <div>
                            {!item.user_image || item.user_name=== "Anonymous" ? (<div style={{background:"rgba(0, 92, 135, 0.05)", borderRadius:"3px", padding:"10px", display:"flex",alignItems:"center"}}>{userIcon()}</div>) : <LazyImage style={{width:"50px",height:"50px"}} src={ImageUrl + "/"+ item.user_image}/>}
                          </div>
                          <div className='user-details'>
                            <div className='username'>{item.user_name}</div>
                            <div className='department'>{item.department_name}</div>
                          </div>
                          
                        </div>
   
                        <div className='sub-row'>
                          <div className='que'><span>Question {feedbackInsights[feedback].question_number}</span></div>
                          <div className='star' onClick={()=>ToggleStar(item.response_id)}>{item.starred ? starIcon("rgba(241, 201, 119, 1)") : starIcon("rgba(0, 92, 135, 0.3)")}</div>
                        </div>
   
                      </div>
   
                      <div className='ans'>
                        <span>{item.answer_text}</span>
                      </div>
   
                      <div className='date'>
                        <span>{item.response_date}</span>
                      </div>
                      
                    </FeedbackCard>
     
                  )))
                )}
              </div>
              {/* <PaginationCard>
                
                {feedbackInsights.previous_page_url ? <UserButton
                  width="30px"
                  height="34px"
                  Align={1}
                  float={1}
                  fontSize="14px"
                  bgColor="rgba(0,92,135,0.10)"
                  capitalize={1}
                  style={{cursor:feedbackInsights.previous_page_url ? "pointer" :"default", opacity:feedbackInsights.previous_page_url ? "1" :"0.5",}}
                  // onClick={() => this.setPagination(pageNo - 1)}
                  onClick={()=>feedbackInsights.previous_page_url && getFeedbackInsights(props.match.params.id,sortValue?.value ? sortValue?.value : "",starred, feedbackInsights.previous_page_url)}
                >
                  <img src="/public/images/CompanyDashBoardV2/NewIcon/leftcrossIcon.png" />
                </UserButton>:null}
   
                {feedbackInsights.next_page_url ? <UserButton
                  width="30px"
                  height="34px"
                  Align={1}
                  marginLeft="10px"
                  fontSize="14px"
                  bgColor="rgba(0,92,135,0.10)"
                  capitalize={1}
                  style={{cursor:feedbackInsights.next_page_url ? "pointer" :"default", opacity:feedbackInsights.next_page_url ? "1" :"0.5",}}
                  onClick={()=>feedbackInsights.next_page_url && getFeedbackInsights(props.match.params.id,sortValue?.value ? sortValue?.value : "",starred, feedbackInsights.next_page_url)}
                  // onClick={() => this.setPagination(pageNo + 1)}
                >
                  <img src="/public/images/CompanyDashBoardV2/NewIcon/rightcrossIcon.png" />
                </UserButton>:null}
              </PaginationCard> */}

              {paginationCount > 0 && (
                <div className="pagination" style={{margin:"20px 0px"}}>
                  {pageNo > 1 && (
                    <UserButton
                      width="32px"
                      height="32px"
                      Align={1}
                      padding="4px"
                      float={1}
                      fontSize="14px"
                      bgColor="rgba(0, 122, 177, 0.05)"
                      capitalize={1}
                      onClick={() =>{
                        setPagination(pageNo - 1);
                        getSurveyFeedback(surveyDetails.companyId,surveyDetails.surveyId,starred,sortValue?.value ? sortValue?.value : 0,pageNo - 1, false);
                      }
                      }
                    >
                      <div
                        style={{
                          transform: "rotate(180deg)",
                          marginLeft: "20px",
                        }}
                      >
                        {" "}
                        {PaginationIcon()}
                      </div>
                    </UserButton>
                  )}
                  {getPaginationCircle()}
                  {pageNo != total && (
                    <UserButton
                      width="32px"
                      height="32px"
                      Align={1}
                      padding="4px"
                      fontSize="14px"
                      bgColor="rgba(0, 122, 177, 0.05)"
                      capitalize={1}
                      onClick={() =>{
                        setPagination(pageNo + 1);
                        getSurveyFeedback(surveyDetails.companyId,surveyDetails.surveyId,starred,sortValue?.value ? sortValue?.value : 0,pageNo + 1, false);
                      }
                      }
                    >
                      <div> {PaginationIcon()} </div>
                    </UserButton>
                  )}
                </div>
              )}
              </React.Fragment>
              
              ): (<NoData style={{color:"rgba(0,92,135,1)"}}>No Data Available</NoData>)}

            </>)}
          </HeaderWrapper>
        </MainEventsContainer>
      </OverlayDashboard>
    </React.Fragment>
  )
}

FeedBack.propTypes = {
  match: PropTypes.object,
  companyInfo: PropTypes.object.isRequired,
  history: PropTypes.object,
  userPermissions: PropTypes.array,
  role:PropTypes.object,
};
  
const mapStateToProps = (state) => ({
  userPermissions: state.profileData.userPermissions,
});
  
export default withRouter(connect(mapStateToProps, null)(FeedBack));