import styled from "styled-components";

// const RegularFont = 'Rubik-Regular';
// const BoldFont = 'Rubik-Medium';
// const ExtraBold = 'Rubik-Bold';

const FeedbackCard = styled.div`
  width: 100%;
  background: white;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 20px;

  .row-one {
    display: flex;
    justify-content: space-between;
    .sub-row {
      display: flex;
      align-items: center;

      .user-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;

        .username {
          font-family: Rubik-Medium;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0px;
          color: rgba(0, 92, 135, 1);
        }
        .department {
          font-family: Rubik-Regular;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0px;
          color: rgba(0, 92, 135, 0.6);
        }
      }

      .que{
        background:rgba(0, 92, 135, 0.10);
        padding:10px 15px;
        color:rgba(0, 92, 135, 1);
        border-radius:3px;
text-align: center;
font-family: Rubik-Medium;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
      }
      .star{
        background:rgba(0, 92, 135, 0.1);
        padding:3px;
        margin-left:10px;
        display:flex;
        align-items:center;
        cursor:pointer;
        width:40px;
        height:40px;
        justify-content:center

      }
    }
  }
  .ans{
    font-family: Rubik-Regular;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0px;
color:rgba(0, 92, 135, 1);
margin:20px 0px;

  }

  .date{
    font-family: Rubik-Regular;
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0px;
text-align: justified;
color:rgba(0, 92, 135, 0.6);

  }
`;

const QuestionDetails = styled.div`
margin:25px 0px;
.title{
  color:#9C9C9C;
font-family: Rubik-Regular;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;

}

.que{
  color:#005C87;
font-family: Rubik-Medium;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px;


}

`

export { FeedbackCard, QuestionDetails };
