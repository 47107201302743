import styled from 'styled-components'

const CategoryCard = styled.div`
  width:100%;
  height:100%;
  border-radius:6px;
  border: ${({ active }) => active ? "2px solid rgba(0, 47, 71, 1)" : 'none'};
  // border: 2px solid rgba(0, 47, 71, 1);
  cursor:pointer;
  .categoryImage{
    width:80px;
    height:80px;
    // background: ${({ backgroundInner, active }) => backgroundInner && active ? backgroundInner : 'rgba(159, 201, 137, 1)'};
    display:flex;
    margin:auto;
    margin-top:25px;
    justify-content: center;
    align-items: center;
    border-radius:6px;
    div{
      >img{
      height:80px;  
      width:80px;
    }

    }
    >img{
      height:80px;  
      width:80px;
    }
  }
  .categoryName{
    margin-top:30px;
    font-family: Rubik-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ color, active }) => color && active ? color : '#005C87'};
    text-align: center;
  }
`;

const CategoryCardWrapper = styled.div`
width: 195px;
height: 195px;
border-radius:6px;
background: ${({ background, active }) => active && background ? background : 'white'};
// background: white;
  display:block;
  .activeArrow{
    width:100%;
    display:flex;
    display:${({ active }) => active ? "flex" : 'none'};
    justify-content:center;
    >img{
      height: 20px;
      width: 29px;
    }
  }
`;

const AssesmentWrapper = styled.div`
width: 100%;
height:100%;
display:flex;
.reportCard{
  width: 24.1%;
}
.questionCard{
  width: calc(75.9% - 25px);
  display:block;
  margin-left: 25px;
}
`;

const InsightReport = styled.div`
width: 100%;

border-radius:6px;
background:white;
.header{
  background:rgba(0, 92, 135, 1);
  display:flex;
  border-radius:6px 6px 0 0;
  font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color:white;
align-items:center;
text-align:center;
height:50px;
justify-content: center;
}
.body{
  width:100%;
  display:block;
  padding: 0px 15px;
background:white;
.cardNoBorder{
  padding:15px 0px;
  display: flex;
  .img{
    width:50px;
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 92, 135, 0.05);
    border-radius:3px;
    >img{
      width:50px;
      height:50px;
      border-radius:3px;
    }
  }
  .name{
    font-family: Rubik;
    font-size: 14px;
font-weight: 400;
line-height: 24px;
color:rgba(0, 92, 135, 1);
margin-left: 15px;
display:flex;
align-items:center;
  }
  .score{
    font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color: rgba(133, 192, 234, 1);
display:flex;
margin:auto;
margin-right:0px;
  }
}
.card-style{
  padding:15px 0px;
  border-bottom: 1px solid rgba(156, 156, 156, 0.2);
  display: flex;
  .img{
    width:50px;
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 92, 135, 0.05);
    border-radius:3px;
    >img{
      width:50px;
      height:50px;
      border-radius:3px;
    }
  }
  .name{
    font-family: Rubik;
    font-size: 14px;
font-weight: 400;
line-height: 24px;
color:#005C87;
margin-left: 15px;
display:flex;
align-items:center;
  }
  .score{
    font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color: rgba(133, 192, 234, 1);
display:flex;
margin:auto;
margin-right:0px;
  }
}
}
`;

const CategoryScoreCard = styled.div`
width: 100%;
margin-top: 15px;
display:block;
background:white;
padding:15px;
.categoryDetails{
width: 100%;

display:flex;
.image{
  width:60px;
  height:60px;
  margin-right:15px;
  margin-bottom:15px;
  >img{
    width:60px;
    height:60px;
  }
}
.name{
  font-family: Rubik;
font-size: 16px;
font-weight: 400;
line-height: 20px;
color: rgba(0, 47, 71, 1);
display:flex;
align-items:center;
height: 60px;
}
}
.score{
  width:100%;
  display:flex;
  justify-content: space-between;

  .value{
    font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color: rgba(253, 113, 117, 1);
color: ${({ color }) => color ? color : 'rgba(157, 135, 169, 1)'};
margin-top:5px;
  }
  }

`;

const QuestionsCard = styled.div`
width: 100%;
background:white;
margin-bottom:25px;
border-radius:7px;
box-shadow: 0px 0px 9px 0px rgba(0, 92, 135, 0.25);
.question{
    display:flex;
    justify-content:space-between;
  padding:15px;
  font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color:#005C87;
border-bottom: 1px solid rgba(0, 92, 135, 0.60);

.que{
  width:81%;
}

.que-type{
    color: rgba(133, 192, 234, 1);
    font-family: Rubik-Regular;
font-size: 18px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0px;
text-transform:capitalize;


}
}
.body{
  display:flex;
  width:100%;
  min-height:346px;
  .circularPercentage{
   width: ${({ width }) => width ? "0%" : '32.6%'};
   background: rgba(247, 245, 235, 0.5);
   display:flex;
   flex-direction:column;
   justify-content:center;
   align-items:center;
   margin:0px;

   .score-details{
        display:flex;
        justify-content:start;
        width:70%;
        padding-top:30px;

        .row{
            display:flex;
            align-items:center;
            .circle{
            width:5px;
            height:5px;
            background-color:rgba(0, 92, 135, 1);
            border-radius:20px;
            margin-right:10px;
        }

        .count{
            font-family: Rubik-Medium;
font-size: 18px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0px;
text-align: left;
color:rgba(0, 92, 135, 1);
        }

        .resp{
            font-family: Rubik;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0px;
text-align: left;
color:rgba(0, 92, 135, 0.6);

        }

        }


      }
  }
  .answers{
    width: ${({ width, widthValue }) =>widthValue?widthValue: width ? "100%" : '67.4%'};
    display: ${({ type }) =>type?  "flex" : ''};
    margin: ${({ type }) =>type?"auto" : ''};
    align-items:center;
    display:flex;
    .circularGraphPercent{
      background: rgba(156, 156, 156, 0.05);
      padding: 25px 60px;
      width:100%;
      .value{
        font-family: Rubik;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color:#005C87;
        margin-top:10px;
      }
      .average{
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color:#005C87;
        padding-left:5px; 
        margin-top:10px;
      }
    }
  }
}
`;

const AnswerWrapper = styled.div`
width: 100%;
display: ${({ display }) => display ? display : 'block'};
flex-wrap: ${({ wrap }) => wrap ? wrap : ''};
padding: ${({ padding }) => padding ? padding : '13px 35px 37px 37px'};
.bargraph{
  height:300px;
}
.circleWrapper{
  display:flex;
  width:100%;
  justify-content: ${({ flex }) => flex ? flex : ''};
}
.line{
  display: flex;
  .fill{
    width:125px;
  }
  .borderLine{
    width: calc(100% - 80px);
    border-top: 1px solid rgba(156, 156, 156, 1);
    display: flex;
    justify-content: space-between;
    .value{
      padding-top:10px;
      font-family: Rubik;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(156, 156, 156, 1);
    }
  }
}

.opinion-scale{
  display:flex;
  justify-content:center;
  align-items:center;
  .score{
    display:flex;
  justify-content:center;
  align-items:center;
  margin-left:23px;

  }
  .score-text{
    color: #005C87;
text-align: center;
font-family: Rubik;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 15px;
margin-left:5px;
  }
}
`;

const AnswerCard = styled.div`
.wrapper{
  width:100%;
  display: ${({ display }) => display ? display : 'block'};
  padding-bottom: ${({ margin }) => margin ? margin : '0px'};
  .optionValue{
    width:80px;
    display:flex;
    align-items: center;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color:#005C87;
    text-align: end;
    justify-content: flex-end;
    text-transform: capitalize;
  }
  .progressGraph{
    width: calc(100% - 80px);
    margin-left: 25px;
    border-top: ${({ border }) => border ? "1px solid rgba(156, 156, 156, 0.2)" : 'none'};
    padding-top:10px;
  }
}
.answerCard{
  width:100%;
  display:block;
  margin-top:22px;
  .answerDataValue{
    width:100%;
    margin-bottom:5px;
    display:flex;
    .first{
      display:flex;
      .optionName{
        font-family: Rubik;
font-size: 14px;
line-height: 24px;
color: rgba(0, 47, 71, 1);
margin-right:10px;
text-transform: capitalize;
      }
      .up{
        font-family: Rubik;
font-size: 12px;
font-weight: 400;
line-height: 24px;
color: rgba(159, 201, 137, 1);
margin-right:5px;
      }
      .down{
        font-family: Rubik;
font-size: 12px;
font-weight: 400;
line-height: 24px;
color: rgba(253, 113, 117, 1);
margin-right: 3px;
      }
      .arrow{
        width: 15px;
    height: 15px;
    margin: auto;
    margin-right: 0px;
    margin-left: 0px;
    align-items: center;
    display: flex;
    >img{
      width: 15px;
      height: 15px;
    }
      }
    } 
    .second{
      font-family: Rubik-Medium;
      font-size: 16px;
      margin: auto;
      margin-right: 0px;
      line-height:24px;
      color: rgba(157, 135, 169, 1);
      color: ${({ color }) => color ? color : 'rgba(157, 135, 169, 1)'};
    }
  }
}

`;

const CircleNew = styled.div`
  width: ${({ width }) => width ? width : '136px'};
  height: ${({ height }) => height ? height : '136px'};
  border-radius: 50%;
  background: white;
padding: 10px;
display: flex;
justify-content: center;
align-items: center;
${'' /* margin: auto; */}
`;

const CircularGraph = styled.div`
width: ${({ width }) => width ? width : '100%'};
cursor:pointer;
.hidePercentCard{
    width: 50px;
    height: 50px;
  .showPercent{
    border-radius: 50%;
    width: 53px;
    height: 53px;
    background: ${({ backgroundActive }) => backgroundActive ? "#fff" : '#fff'};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 10px;
    margin-left: 10px;
    padding: 17px;
    font-family: 'Rubik';
    font-size: 14px;
    color: #005C87;
    box-shadow: -2px 25px 25px rgba(0, 0, 0, 0.1);
    border: ${({ backgroundActive }) => backgroundActive ? `2px solid ${backgroundActive}` : 'rgba(159, 201, 137, .15)'};
  }
}


.circleContainer{
  border-radius:50%;
  width:195px;
  margin: auto;
  background: ${({ backgroundColor}) =>  backgroundColor ? backgroundColor : 'rgba(156, 156, 156, .10)'};
.circle{
  display: block;
  width: 195px;
  height: 195px;
  border-radius: 50%;
  background-image: ${({ circleWidth, background }) => background ?`conic-gradient(${background} ${circleWidth}deg,transparent 0 235deg,transparent 0)`: '136px'};
  font-family: Rubik-Medium;
  font-size: 16px;
  line-height: 11px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  color: ${({ color}) =>  color ? color : 'white'};
}
}
.option{
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color:#005C87;
  display:flex;
  justify-content:Center;
  text-align: ${({ align}) =>  align ? "center" : 'center'};
  padding: ${({ padding }) => padding ? padding : '20px 0px 0px 0px'};
  text-transform: capitalize;
}
`;

const MultipleAnswerCard = styled.div`
width: ${({ width }) => width ? width : '280px'};
border: 1px solid rgba(156, 156, 156, 1);
border-radius:6px;
background: white;
height:80p;
padding:15px;
margin-right: ${({ margin }) => margin ? margin : '15px'};
display: flex;
flex-wrap: wrap
margin-bottom:10px;
.option{
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color:#005C87;
  padding-bottom: 5px;
  text-transform: capitalize;
}
.bar{
  width: 100%;
  margin: auto;
  display: flex;
  margin-bottom: 0px;
}
`;

const DropdownWrapper = styled.div`
  display:flex;
  margin:auto;
  margin-right:15px;
  .label{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #005C8799;
    padding-right:10px;
    align-items: center;
    display: flex;
  }
`;

export { CategoryCard, CategoryCardWrapper, AssesmentWrapper, InsightReport, CategoryScoreCard, QuestionsCard, AnswerWrapper, AnswerCard, CircleNew, CircularGraph, MultipleAnswerCard, DropdownWrapper }